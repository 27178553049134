import {changeRoutesByPurposeOfUseForAjaxfileuploader} from "../../../../../../OP/js/lib/globalfunctions";

/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Kein Abschicken eines Formulares bei "Enter"
 */
jQuery(function () {
    let $document = jQuery(document);
    $document.on("start load", function () {
        jQuery('form input, form select')
            .not(".formSubmitNoEnter-loaded, #password")
            .addClass("formSubmitNoEnter-loaded")
            .on('keypress keydown', function (e) {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            });
        let $fileUploadFieldTypeVerwendungszweckItems = jQuery('.fileUploadFieldTypeVerwendungszweck');
        $fileUploadFieldTypeVerwendungszweckItems.each(function () {
            let $thisFileUploadFieldTypeVerwendungszweck = jQuery(this);
            // @ts-ignore
            if ($thisFileUploadFieldTypeVerwendungszweck[0] !== undefined && jQuery._data($thisFileUploadFieldTypeVerwendungszweck[0], 'events') !== undefined) {
                jQuery($thisFileUploadFieldTypeVerwendungszweck[0]).off('change');
            }
            $thisFileUploadFieldTypeVerwendungszweck.not('.fileUploadFieldTypeVerwendungszweck-loaded').add('fileUploadFieldTypeVerwendungszweck-loaded')
                .on('change', function () {
                    let $this = jQuery(this);
                    let fieldNameToSet = $this.val();
                    let fieldName = $this.data('fieldname');
                    if (fieldNameToSet === '0') {
                        fieldNameToSet = 'Sonstige_Dokumente';
                    }
                    if ($this.data('verwendungszweck') === undefined || $this.data('verwendungszweck') !== fieldNameToSet) {
                        let $pzsUpload = $this.closest('.pzs-upload');
                        let fieldToReplaceIdOrName = $pzsUpload.find('input[id$="' + fieldName + '"]');
                        let patternToReplace = new RegExp(fieldName, 'g');
                        // @ts-ignore
                        fieldToReplaceIdOrName.attr('name', fieldToReplaceIdOrName.attr('name').replace(patternToReplace, fieldNameToSet));
                        if (fieldToReplaceIdOrName.attr('id') !== undefined) {
                            // @ts-ignore
                            fieldToReplaceIdOrName.attr('id', fieldToReplaceIdOrName.attr('id').replace(patternToReplace, fieldNameToSet));
                        }
                        $this.data('verwendungszweck', fieldNameToSet);
                        $this.data('partStringToReplaceInnerRoute', 'verwendungszweck=' + fieldNameToSet);
                        let ajaxfileuploader = $pzsUpload.find('.ajaxfileuploader');
                        let ajaxFileUploaderFileCountField = $pzsUpload.find('.ajaxfileuploader').find('.ajaxfileuploader-filecount');
                        ajaxFileUploaderFileCountField.attr('name', fieldToReplaceIdOrName.attr('name'));
                        changeRoutesByPurposeOfUseForAjaxfileuploader(ajaxfileuploader, $this);
                        $this.data('fieldname', fieldNameToSet); // pass fieldname to the chosen verwendungszweck
                    }
                });
        });
    }).trigger("start");
});

