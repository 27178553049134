jQuery.fn.extend({
    dataAttr: function (key, value) {
        jQuery(this).data(key, value).attr('data-' + key, value);
    },
    toFloat: function () {
        let floats = [];
        jQuery(this).each(function () {
            floats.push(getFloat(jQuery(this).val()));
        });
        if (floats.length === 1) {
            return floats[0];
        }
        return floats;
    },
    sum: function () {
        let sum = 0;
        jQuery(this).each(function () {
            sum += getFloat(jQuery(this).val());
        });
        return sum;
    }
});