jQuery(function () {
    var $flashbag = null;
    var $container = null;

    jQuery(document).bind("start load", function () {
        jQuery("body").not(".flashbag-loaded").addClass("flashbag-loaded").each(function () {
            var $body = jQuery(this);
            $flashbag = $body.find("#flashbag");
            $container = $flashbag.find(".flashbag-container");

            $flashbag.bind("update", function () {
                $flashbag.find('.alert-error').addClass("alert-danger");
                $flashbag.find('.alert-alert-ok').addClass("alert-success");
                $flashbag.find('.alert').not(".registered").addClass("registered").each(function () {
                    var $message = jQuery(this);
                    $message.addClass("come");
                    $message.bind("dblclick", function () {
                        jQuery(this).remove();
                    });
                    $message.find(".close").bind("click", function () {
                        jQuery(this).closest(".alert").remove();
                    });
                    var delay = 10000;
                    if ($message.hasClass("alert-danger")) {
                        delay = 99000000;
                    }
                    window.setTimeout(function () {
                            $message.addClass("leave");
                        }, delay
                    );
                    window.setTimeout(function () {
                        $message.remove();
                    }, delay + 1000
                    );
                });
            });
            $flashbag.bind("truncate", function () {
                $flashbag.find('.alert').remove();
            });
            $flashbag.bind("add", function (e, label, message) {
                if (label === "ok") label = "success";
                if (label === "error") label = "danger";
                message = message.replace(/\n/g, "<br>");
                var $msg = jQuery('<div class="alert alert-' + label + '"><span class="clickable close btn btn-' + label + '">&times;</span>' + message + '</div>');
                $msg.appendTo($container);
                $flashbag.trigger("update");
            });
            // alias // todo aufräumen
            $flashbag.bind("addflash", function (e, label, message) {
                $flashbag.trigger("add", [label, message]);
            });
            $flashbag.trigger("update");

            // durchreichen - das andere Javascript soll den Flashbag nicht kennen,
            // sondern einfach ein Ereignis auf dem body auslösen:

            $body.bind('addmessage', function (e, label, message) {
                $flashbag.trigger('add', [label, message]);
            });
            $body.bind('truncatemessages', function () {
                $flashbag.trigger('truncate');
            });
            // @ts-ignore
            window.addflash = function (label, message) {
                $flashbag.trigger('add', [label, message]);
            };
            // @ts-ignore
            window.truncateflash = function () {
                $flashbag.trigger('truncate');
            };
        });
    }).trigger("start");
});

