/**
 *
 * @param element # HTMLElement
 * @param message
 * @param messageType # danger, warning, success
 * @returns {boolean}
 */
var showWarningByElement = (/** @type {any} */ element, /** @type {string} */ message, /** @type {string} */ messageType) => {
    element.trigger('addmessage', [
        messageType,
        '<p class="addmessageAlignText">' + message + '</p>'
    ]);
    return false;
};

/**
 *
 * @param ajaxfileuploader # HTMLElement
 * @param verwendungszweckElement # HTMLElement or string
 * @returns {*}
 */
var changeRoutesByPurposeOfUseForAjaxfileuploader = (/** @type {any} */ ajaxfileuploader, /** @type {any} */ verwendungszweckElement) => {
    let fieldName;
    let verwendungszweck;
    if (typeof verwendungszweckElement === 'object') {
        fieldName = verwendungszweckElement.data('fieldname');
        verwendungszweck = verwendungszweckElement.data('verwendungszweck');
    }
    if (fieldName === undefined && verwendungszweck === undefined) {
        fieldName = ajaxfileuploader.data('settings_meta').verwendungszweck;
        verwendungszweck = verwendungszweckElement;
    }

    $.each(ajaxfileuploader.data(), function (index, value) { // replace all routes with changed verwendungszweck
        if (typeof value === 'object') {
            jQuery.each(value, function (indexInner, valueInner) {
                // @ts-ignore
                if (indexInner.match(/verwendungszweck/) !== null) {
                    ajaxfileuploader.data(index.toLocaleString())[indexInner] = verwendungszweck;
                }
            });
        } else if (isNaN(parseInt(value))) {
            let routeToSet = null;
            if (value.match(/verwendungszweck/) !== null) {
                routeToSet = ajaxfileuploader.data(index.toLocaleString()).replace(
                    new RegExp('verwendungszweck=' + fieldName, 'g'),
                    'verwendungszweck=' + verwendungszweck);
            } else if (value.match(new RegExp(fieldName))) {
                routeToSet = ajaxfileuploader.data(index.toLocaleString()).replace(
                    new RegExp(fieldName),
                    verwendungszweck);
            }

            if (routeToSet !== null) {
                ajaxfileuploader.data(index.toLocaleString(), routeToSet);
            }
        }
    });

    return ajaxfileuploader;
};

export {changeRoutesByPurposeOfUseForAjaxfileuploader as changeRoutesByPurposeOfUseForAjaxfileuploader};
export {showWarningByElement as showWarningByElement};
