/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Wenn das data-tooltip Attribut gefunden wird gibt es eine ToolTip-Blase.
 *
 * @autor pbierans,2020
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        jQuery('[datatip-toggle=tooltip], [data-toggle=tooltip], [data-tooltip]')
            .not(".tooltip-loaded")
            .addClass("tooltip-loaded")
            // @ts-ignore
            .tooltip({html: true}); //Bootstrap!
    }).trigger("start");
});
