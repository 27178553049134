/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Beschränkt den Inhalt eines HTML-Elementes.
 * Geht man mit dem Mauszeiger grüber wird der Name angezeigt.
 *
 * @autor pbierans,2020
 * @ticket ADB-1356
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        var $set = jQuery("[data-limit-length]").not(".limit-length-loaded").addClass("limit-length-loaded").each(function () {
            var $this = jQuery(this);
            var text = $this.text().trim();
            var wrap = $this.data("limit-wrap") || 20;
            var len = $this.data("limit-length") || 100;
            var max = $this.data("limit-length-cutoff") || 255;
            var mode = $this.data("limit-length-mode") || "filename";
            var regex = new RegExp("(.{" + wrap.toString() + "})", "g");
            if (text.length > len - 5) {
                if (text.length > max - 5) {
                    if (mode === "filename") {
                        text = text.substr(0, max - 5) + "~" + text.substr(text.length - 5, 5);
                    } else {
                        text = text.substr(0, max) + "...";
                    }
                }
                $this.attr("title", text.replace(regex, "$1 "));
                $this.addClass("truncated");
                if (mode === "filename") {
                    text = text.substr(0, len - 5) + "~" + text.substr(text.length - 5, 5);
                } else {
                    text = text.substr(0, len) + "...";
                }
                $this.html(text);
                $this.attr("data-tooltip", "tooltip");
            }
        });
        if ($set.length > 0) {
            jQuery(document).trigger("start"); //falls sich ein tooltip plugin registrieren will
        }
    }).trigger("start");
});
