jQuery(function () {
    // muss direkt geladen werden!

    var $slh = jQuery("script#checkForNewGlobalMessages");
    if ($slh.length === 0) {
        return; // anon user - no check
    }
    var latesthash = $slh.data("latesthash");
    var interval = parseInt($slh.data("interval"));
    var increase = parseInt($slh.data("increase"));
    var cease = parseInt($slh.data("cease"));
    var uri = $slh.data("uri");

    if (interval < 15000) {
        interval = 15000;
    }

    var checkForNewGlobalMessages = function () {
        var ts = new Date().getTime().toString();
        jQuery.getJSON(uri + "?ts=" + ts).done(function (data) {
            var $messages = jQuery("#messages");
            var mcount = $messages.find("div.alert").length;
            if (data.hash !== undefined && data.hash !== latesthash) {
                $messages.find("div.alert").fadeOut(function () {
                    jQuery(this).remove();
                });
            }
            if (data.hash !== undefined && data.hash !== null && data.hash !== latesthash) {
                var start = new Date(data.start);
                var until = new Date(data.until);
                var now = new Date();

                if (data.title !== null && now > start && now < until) {
                    var title = "Eine neue Systemmitteilung liegt vor";
                    if (mcount > 0) {
                        title = "Eine neue oder geänderte Systemmitteilung liegt vor";
                    }
                    if (jQuery("#content form, #content-bootstrap form").length > 0) {
                        // @ts-ignore
                        addflash("info", title + ":<br/>Bitte speichern Sie das Formular zeitnah.");
                    } else {
                        // addflash("info", title + '.');
                    }
                    var $msg = jQuery('<div class="alert alert-' + data.type + '" role="alert" onclick="jQuery(this).fadeOut()"><strong>' + data.title + '</strong><br>' + data.description + '</div>');
                    $msg.hide();
                    $messages.append($msg);
                    window.setTimeout(function () {
                        $msg.show();
                    }, 2000);
                }
                latesthash = data.hash;
            }
            if (interval < cease) {
                interval += increase;
                window.setTimeout(checkForNewGlobalMessages, interval);
            }
        });
    };
    window.setTimeout(checkForNewGlobalMessages, 1000);
});
