/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Wenn eine TABLE die CSS-Klasse toggle-row bekommt,
 * so wird für jede TD oder TH, wie das Attribut data-toggle-row bekommt beim Anklicken
 * auf die erste Checkbox innerhalb der zugehörigen TR geklickt.
 *
 * @author bierans,2020
 * @ticket ADB-1356, ADB-1395
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        var $table = jQuery("table.toggle-row");
        $table.not(".toggle-row-loaded").addClass("toggle-row-loaded")
            .bind("click", function (e) {
                e.stopPropagation();
                var $target = jQuery(e.target);
                if ($target.is("[data-toggle-row]")) {
                    $target.closest("tr").find("input[type=checkbox],input[type=radio]").click();
                }
            });
    }).trigger("start");
});
