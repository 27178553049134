/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Hat ein HTML-Element das Attribut data-ajax-replace,
 * so wird der Wert des Attributes als API-Punkt mit GET aufgerufen
 * und das HTML-Element wird durch die Antwort des Requests ersetzt.
 *
 * @autor pbierans,2020
 * @ticket ADB-1356, ADB-1395
 */
jQuery(function () {
    jQuery(document).on("start load", function () {
        jQuery("[data-ajax-replace]").not(".loaded").addClass("loaded").each(function () {
            var $this = jQuery(this);
            var uri = $this.data("ajax-replace");
            var interval = $this.data("ajax-interval");
            $this.load(uri, function () {
                jQuery(this).children(':first').unwrap();
                jQuery("body").trigger("load");
                jQuery(document).trigger("load");
                jQuery(document).trigger("initializeMap");
            });
            if (interval) {
                window.setInterval(() => {
                    $this.load(uri, function () {
                        jQuery(this).children(':first').unwrap();
                        jQuery("body").trigger("load");
                        jQuery(document).trigger("load");
                        jQuery(document).trigger("initializeMap");
                    });
                }, parseInt(interval));
            }
        });
    }).trigger("start");
});
