/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @ts-nocheck debounce_timeout global in window object
 * @var {null|number} debounce_timeout
 */
// @ts-nocheck

jQuery(function () {
    // muss direkt geladen werden!

    // jQuery(document).bind("start load", function () {
    var timeouts = {};

    global.debounce = debounce = function (func, wait, immediate, alias) {
        if (alias === undefined) alias = "default";
        return function () {
            var context = this;
            var args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeouts[alias]);
            timeouts[alias] = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };
    // }).trigger("start");
});
