/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Wenn auf eine TR geklickt wird, die ein HREF-Attribut hat,
 * wird das href wie ein Link einfach geöffnet.
 * Ausnahme: Man klickt auf einen Button in einer TR
 *
 * @autor pbierans,2020,2022
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        jQuery("tr[href], li[href]").not('.tr-href-loaded').addClass('tr-href-loaded').bind('click', function (e) {
            if (e.target.classList.contains('btn')) return;
            var $this = jQuery(this);
            var url = $this.attr("href");
            var target = $this.data('target');
            if (target !== undefined) {
                window.open(url, target);
                return;
            }
            window.location.href = url;
        });
    }).trigger("start");
});
