/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Wenn Jemand ESC drück wird ein eingenes escape Event auf dem BODPY getriggert.
 *
 * @autor pbierans,2020
 * @ticket ADB-1356
 * @see thumbnail.js (closes overlay)
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        var $body = jQuery("body");
        $body = $body.not(".event-escape").addClass("event-escape");
        $body.bind("keydown", function (e) {
            if (e.key === "Escape") {
                $body.trigger("escape");
            }
        });
    }).trigger("start");
});
