/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 *
 * Wenn dieser Event getriggert wird, wird eine ggf vorhadndene Markierung auf der Webseite deselektiert.
 *
 * @autor pbierans,2020
 * @ticket ADB-1356
 * @see thumbnail.js
 */
jQuery(function () {
    jQuery(document).bind("start load", function () {
        var $body = jQuery("body");
        $body = $body.not(".event-unselect").addClass("event-unselect");
        $body.bind("unselect", function (e) {
            if (window.getSelection) {
                if (window.getSelection().empty) {
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {
                    window.getSelection().removeAllRanges();
                }
            }
        });
    }).trigger("start");
});
