/**
 * IST IMMER GELADEN UND VERFÜGBAR! (base.html.twig)
 */
// @ts-nocheck

jQuery(function () {

    var tpl_dialog = ''
        + '<div class="modal-overlay dialog">'
        + '  <div class="modal-center">'
        + '    <div class="modal-window">'
        + '      <div class="dialog-title"></div>'
        + '      <div class="dialog-message"></div>'
        + '      <div class="dialog-options">'

        + '      </div>'
        + '    </div>'
        + '  </div>'
        + '</div>'
    ;
    var tpl_dialogOption = ''
        + '        <div class="dialog-option btn">'
        + '          <span class="glyphicon"></span>'
        + '          <span class="dialog-option-label"></span>'
        + '        </div>'
    ;

    var $dialog = false;
    var $title = false;
    var $message = false;
    var $options = false;

    /*global*/
    hideDialog = function () {
        if ($dialog) {
            $dialog.fadeOut(150,function(){
                $dialog.removeClass("visible");
            });
        }
    }
    var $body = jQuery("body");
    $body.bind("escape", hideDialog);

    var resetDialog = function () {
        if ($dialog === false) {
            $dialog = jQuery(tpl_dialog);
            $dialog.appendTo("body");
            $title = $dialog.find(".dialog-title");
            $message = $dialog.find(".dialog-message");
            $options = $dialog.find(".dialog-options");
            $dialog.bind('click',hideDialog);
        }
        $dialog.removeClass("visible");
        $title.html('');
        $message.html('');
        $options.html('');
    };

    var addOption = function (settings) {
        $option = jQuery(tpl_dialogOption);
        $label = $option.find('.dialog-option-label');
        $icon = $option.find('.glyphicon');
        if (settings.label != undefined) $label.html(settings.label);
        if (settings.class != undefined) $option.addClass(settings.class);
        if (settings.icon != undefined) {
            $icon.addClass(settings.icon);
        } else {
            $icon.remove();
        }
        if (settings.label == undefined && settings.icon == undefined){
            $label.html('Ok');
        }
        if (settings.click != undefined) {
            $option.addClass('clickable');
            $option.bind("click", function(){
                settings.click();
                hideDialog();
            });
        } else {
            $option.bind("click", hideDialog);
        }
        $option.appendTo($options);
    }

    /*global*/
    showDialog = function (settings) {
        resetDialog();
        if (settings.title) $title.html(settings.title);
        if (settings.message) $message.html(settings.message);
        if (settings.options) {
            for (var key in settings.options) {
                addOption(settings.options[key]);
            }
        }
        if (settings.options == undefined || settings.options.length==0){
            addOption({'class':'btn-default'});
        }
        $dialog.addClass("visible");
        $dialog.stop(true,true).hide().fadeIn(150,function(){
            $body.trigger("unselect");
        });

    }

    /*global*/
    showDialogHint = function (message){
        showDialog({
            'title' : 'Hinweis',
            'message' : message
        });
    }

});
