/**
 * MUSS EXPLIZIT GELADEN WERDEN!
 *
 * Man nimmt ein Block-Element wie ein DIV oder ein FORM.
 * Statte es dann mit dem Attribut data-pagination aus.
 * Dann wird dieses Script innerhalb dieses Elementes folgende Attribute suchen:
 *
 * data-pagination-page = aktuelle Seite
 * data-pagination-perpage = Anzahl Treffer pro Seite
 * data-pagination-rows = Anzahl Treffer
 * data-pagination-api-batchdelete = URL zu einer Controlleraction zum Löschen einer Auswahl von Nodes
 * data-pagination-api-batchdownload = URL zu einer Controlleraction zum Herunterladen einer Auswahl von Nodes als ZIP
 * data-pagination-api-filelist = URL zu einer Controlleraction zur Navigation innerhalt einer Node-Ergebnismenge
 *
 * Und innerhalb dieses RootNodes folgende HTML-Elemente:
 *
 * .badge.pagination-selection-count = Anzahl der ausgewählten Elemente
 * .badge.pagination-total = innerHTML = Anzahl der Ergebnismenge total
 * .pagination-action.reload = Button zum Neuladen der Inhalte im TABLE
 * .pagination-action.batchdelete = Button zum Löschen der Auswahl
 * .pagination-action.batchdownload = Button zum Herunterladen der Auswahl
 * .pagination-action.prev = Vorherige Seite
 * .pagination-action.next = Nächste Seite
 * .pagination-subject = TABLE
 * .pagination-subject .pagination-loading = TR für "Keine Daten / Daten werden geladen"
 * .pagination-target = Checkboxen innerhalb der TABLE (eine pro ROW wäre toll)
 *
 * [name=page] = Input Element mit der aktuellen Seitennummer
 * [name=perpage] = Selectbox mit der Anzahl Treffer pro Seite
 * [name=sortingby] = Selectbox mit dem Feld, nachdem sortiert werden soll
 * [name=sortingdir] = Selectbox mit der Sortierrichtung
 *
 * @see uploadSorter.js
 *
 * @author pbierans,2020
 * @ticket ADB-1356, ADB-1395
 */
jQuery(function () {
    jQuery(document).on("start load", function () {
        let $body = jQuery("body");
        jQuery("[data-pagination]")
            .not(".data-pagination-loaded")
            .addClass("data-pagination-loaded")
            .each(function () {
                let $root = jQuery(this);
                let api_batchdelete = $root.data("pagination-api-batchdelete");
                let api_batchdownload = $root.data("pagination-api-batchdownload");
                let api_filelist = $root.data("pagination-api-filelist");

                let $badges = $root.find(".pagination-selection-count"); // Alle Positionen, wo die Anzahl der Selection eingetragen wird (div oder span innerHTML)
                let $count = $root.find(".pagination-total"); // Alle Positionen, wo die max. Anzahl von Treffern eingetragen wird
                let $pages = $root.find(".pagination-pages-total"); // Nur Ausgabe für User

                let $table = $root.find(".pagination-subject"); // TABLE-Knoten dessen TBODY ersetzt wird
                let $loading = $table.find("tbody").html(); // Inhalt von $table, wenn keine Daten geladen sind. ein TBODY
                let $filters = $table.find(".pagination-filter");
                let $trfilter = $filters.closest('tr.filter');

                let $th = $table.find("thead th[data-field]");

                let $target;
                $table.on("start load reload ready update", function () {
                    $target = $table.find(".pagination-target"); // checkboxes zur Auswahl von Rows, wird öfter aufgerufen zum Aktualisieren
                }).trigger("start");
                $table.on("click", function (e) {
                    e.stopPropagation();
                    let $target = jQuery(e.target);
                    if ($target.is(".pagination-target")) {
                        $table.trigger("changed");
                    }
                });
                $table.on("changed", function () {
                    let $checked = $table.find(".pagination-target:checked");
                    $badges.html($checked.length.toString());
                    let $total = $table.find("[data-pagination-total]");
                    if ($total.length > 0) {
                        $count.html($total.data("pagination-total"));
                    }
                    let $maxpage = $table.find("[data-pagination-maxpage]");
                    if ($maxpage.length > 0) {
                        $pages.html($maxpage.data("pagination-maxpage"));
                    }

                    $body.trigger('load');
                });

                let $btnReload = $root.find(".pagination-action.reload"); // btn
                let $btnDelete = $root.find(".pagination-action.batchdelete");
                let $btnDownload = $root.find(".pagination-action.batchdownload");
                let $btnPrev = $root.find(".pagination-action.prev"); // page-- && $btnReload.click()
                let $btnNext = $root.find(".pagination-action.next"); // page++ && $btnReload.click()
                let $btnSearch = $root.find(".pagination-action.search");
                let $btnNoSearch = $root.find(".pagination-action.nosearch");
                let $btnSort = $root.find(".pagination-action.sort");

                // user input fields
                let $page = $root.find("[name=page]"); // Aktuelle Seite
                let $perpage = $root.find("[name=perpage]"); // Pro Seite
                let $sortingby = $root.find("[name=sortingby]"); // Sortierung nach
                let $sortingdir = $root.find("[name=sortingdir]"); // Sortier-Richtung
                let $search = $root.find("[name=search]"); // Suchfeld

                /**
                 * @param $checked jQuery Array
                 * @returns {string[]}
                 */
                let getSelection = function ($checked) {
                    let nodes = [];

                    for (let i = 0; i < $checked.length; i++) {
                        if ($checked[i].value !== undefined) {
                            nodes.push($checked[i].value);
                        }
                    }
                    return nodes;
                };
                /**
                 * @returns {number}
                 */
                let getPage = function () {
                    let page = parseInt($page.val() + "");
                    if (page < 1) {
                        page = 1;
                    }
                    return page;
                };
                /**
                 * @returns {number}
                 */
                let getPerPage = function () {
                    let perpage = parseInt($perpage.find(":selected").val() + "");
                    if (perpage < 5) {
                        perpage = 5;
                    }
                    return perpage;
                };
                /**
                 * @returns {number}
                 */
                let getCount = function () {
                    return parseInt($count.html() + "");
                };
                /**
                 * @returns {number}
                 */
                let getMaxPage = function () {
                    let perPage = getPerPage();
                    let max = getCount();
                    return Math.ceil(max / perPage);
                };
                let getSortingBy = function () {
                    return $sortingby.find(":selected").val();
                };
                let getSortingDir = function () {
                    return $sortingdir.find(":selected").val();
                };
                let getFilters = function () {
                    let filter = {};
                    $filters.each(function () {
                        let $select = jQuery(this);
                        let val = $select.val();
                        let $td = $select.closest("td");
                        $td.removeClass("hasfilters");
                        if (val) {
                            $td.addClass("hasfilters");
                            let name = $select.attr('name');
                            filter[name] = val;
                        }
                    });
                    if (filter.length > 0) {
                        $trfilter.addClass('hasfilters');
                    } else {
                        $trfilter.removeClass('hasfilters');
                    }
                    return filter;
                };
                let getSearch = function () {
                    if ($search.length > 0) {
                        if (typeof $search.val() === "undefined") {
                            return '';
                        }
                        return ($search.val() + "").trim();
                    }
                };

                let doReloadTimer = null;
                let doReloadTimerAS = null; // ajaxfileuploader plugin
                let doReload = function () {
                    $count.html("...");
                    $root.addClass("loading");
                    jQuery("[data-afu-disclaimer]").removeClass("alert-warning");
                    let page = getPage();
                    let perpage = getPerPage();
                    $table.find("tbody").html($loading);

                    let params = {
                        'page': page.toString(),
                        'perpage': perpage.toString(),
                        'sortingby': getSortingBy(),
                        'sortingdir': getSortingDir(),
                        'filters': getFilters(),
                        'search': getSearch()
                    };
                    let uri = api_filelist.concat('?', jQuery.param(params));

                    $table.find("tbody").load(uri, function () {
                        if ($table.find("form#login").length > 0) {
                            $body.trigger("addflash", ["info", "Session abgelaufen."]);
                            jQuery(this).html("Session abgelaufen.");
                            window.location.reload();
                        }
                        $root.removeClass("loading");
                        $table.trigger("changed");
                        jQuery(document).trigger('load');
                        // $search.focus().select();
                    });
                };
                let prepareReload = function (startover) {
                    if (doReloadTimer) {
                        window.clearTimeout(doReloadTimer);
                    }
                    if (startover) {
                        $page.val(1);
                    }
                    doReloadTimer = window.setTimeout(doReload, 666);
                };
                let filterTable = function () {
                    let txt = getSearch().toLowerCase();
                    // @ts-ignore
                    $table.find("tbody tr").each(function () {
                        let $tr = jQuery(this);
                        if (txt === "") return $tr.show();
                        if ($tr.text().toLowerCase().indexOf(txt) > -1) {
                            $tr.show();
                        } else {
                            $tr.hide();
                        }
                    });
                    prepareReload(true);
                };

                let currentSortingBy = getSortingBy();
                let currentSortingDir = getSortingDir();
                let allSortingBy = {};
                $sortingby.find("option").each(function () {
                    allSortingBy[jQuery(this).val()] = true;
                });
                let toggleSortDir = function () {
                    if (currentSortingDir === "asc") currentSortingDir = "desc";
                    // nah else if (currentSortingDir === "desc") currentSortingDir = "";
                    else currentSortingDir = "asc";
                    showSorting();
                };
                let setSortField = function (field) {
                    if (allSortingBy[field] === undefined) {
                        return console.log("nope for " + field);
                    }
                    if (currentSortingBy !== field) {
                        currentSortingBy = field;
                        currentSortingDir = "";
                    }
                    toggleSortDir();
                    prepareReload();
                };
                let showSorting = function () {
                    $sortingby.val(currentSortingBy);
                    $sortingdir.val(currentSortingDir);
                    $th.removeClass("sorting-dir-").removeClass("sorting-dir-asc").removeClass("sorting-dir-desc");
                    if (currentSortingBy) {
                        $th.filter("[data-field='" + currentSortingBy + "']").addClass("sorting-dir-" + currentSortingDir);
                    }
                };
                $th.each(function () {
                    let $this = jQuery(this);
                    let field = $this.data("field");
                    if (allSortingBy[field] === undefined) return; // skip
                    $this.addClass("sort-loaded").on("click", function () {
                        setSortField(field);
                    });
                });

                $btnDelete.not(".btn-delete-loaded").addClass("btn-delete-loaded").on("click", function () {
                    $table.trigger("ready");
                    let $checked = $target.filter(":checked");
                    if ($checked.length < 1) {
                        return alert("Bitte wählen Sie zuerst Dateien aus.");
                    }
                    if (!confirm("Wirklich löschen?")) {
                        return; // console.log("canceled");
                    }
                    $count.html("");
                    let nodes = getSelection($checked);
                    jQuery("#flashbag").trigger("addflash", ["info", nodes.length + " Dateien werden jetzt gelöscht..."]);
                    $checked.closest("tr").remove();
                    $table.trigger("changed");
                    jQuery.post(api_batchdelete, {nodes: nodes}, function (data) {
                        if (data.error) {
                            return alert(data.error);
                        }
                        // noinspection JSUnresolvedVariable
                        if (data.deleted !== undefined) {
                            // noinspection JSUnresolvedVariable
                            jQuery("#flashbag").trigger("addflash", ["success", data.deleted + " Dateien wurden gelöscht."]);
                        } else {
                            jQuery("#flashbag").trigger("addflash", ["warning", "Keine Dateien wurden gelöscht."]);
                        }
                    });
                });

                $btnDownload.not(".btn-download-loaded").addClass("btn-download-loaded").on("click", function () {
                    $table.trigger("ready");
                    let $checked = $target.filter(":checked");
                    if ($checked.length < 1) {
                        return alert("Bitte wählen Sie zuerst Dateien aus.");
                    }
                    let nodes = getSelection($checked);
                    jQuery("#flashbag").trigger("addflash", ["info", "Der Download einer ZIP-Datei mit " + nodes.length + " Bildern wird beantragt..."]);
                    jQuery.post(api_batchdownload, {nodes: nodes}, function (data) {
                        if (data.success !== undefined) {
                            jQuery("#flashbag").trigger("addflash", ["info", "Der Download einer ZIP-Datei wurde beantragt. <br/>Oben rechts finden Sie den Downloadlink, sobald das ZIP-Archiv bereit ist."]);
                            jQuery(document).trigger("joblist-update");
                        } else {
                            jQuery("#flashbag").trigger("addflash", ["warning", "Der Download einer ZIP-Datei konnte nicht beantragt werden."]);
                        }
                    });
                });

                $sortingby.on("change", function () {
                    currentSortingBy = getSortingBy();
                    showSorting();
                    prepareReload();
                });

                $sortingdir.on("change", function () {
                    currentSortingDir = getSortingDir();
                    showSorting();
                    prepareReload();
                });

                $perpage.on("change", function () {
                    prepareReload(true);
                });

                $page.on("change", function () {
                    let page = getPage();
                    let max = getMaxPage();
                    if (page > max && max > 0) $page.val(max);
                    if (page <= 1) $page.val(1);
                    prepareReload();
                });

                $page.on("enter click", function () {
                    jQuery(this).focus().select();
                });

                $search.on("enter", function () {
                    jQuery(this).focus().select();
                });

                $search.on("keyup keypress", function (e) {
                    let keyCode = e.keyCode || e.which;
                    switch (keyCode) {
                        case 13:
                            // ENTER
                            e.preventDefault();
                            e.stopPropagation();
                            prepareReload(true);
                            return false;
                    }
                    // filterTable();
                    prepareReload();
                });

                $page.not(".input-page-loaded").addClass("input-page-loaded").on('keyup keypress', function (e) {
                    let keyCode = e.keyCode || e.which;
                    switch (keyCode) {
                        case 13:
                            // ENTER
                            e.preventDefault();
                            e.stopPropagation();
                            prepareReload();
                            return false;
                        case 37:
                        case 40:
                            // LEFT + DOWN
                            $btnPrev.trigger("click");
                            break;
                        case 38:
                        case 39:
                            // RIGHT + UP
                            $btnNext.trigger("click");
                            break;
                    }
                });

                $btnPrev.not(".btn-prev-loaded").addClass("btn-prev-loaded").on("click", function () {
                    let page = getPage();
                    let max = getMaxPage();
                    if (isNaN(page) || page > max) {
                        page = max + 1;
                    }
                    if (page <= 1) {
                        return;
                    }
                    page--;
                    $page.val(page.toString());
                    prepareReload();
                });

                $btnNext.not(".btn-next-loaded").addClass("btn-next-loaded").on("click", function () {
                    let page = getPage();
                    let oldpage = page;
                    let max = getMaxPage();
                    if (isNaN(page)) {
                        page = 0;
                    }
                    if (page >= max) {
                        page = max - 1;
                    }
                    page++;
                    if (page === oldpage) {
                        return;
                    }
                    $page.val(page.toString());
                    prepareReload();
                });

                $btnNoSearch.on("click", function () {
                    $search.val("").focus();
                    prepareReload(true);
                });

                $btnSearch.on("click", function () {
                    prepareReload(true);
                });

                $btnSort.on("click", function () {
                    prepareReload(true);
                });

                $btnReload.not(".btn-reload-loaded").addClass("btn-reload-loaded").on("click", function () {
                    prepareReload();
                });

                $filters.on('change', function () {
                    getFilters();
                    prepareReload(true);
                });

                showSorting();
                getFilters();
                doReload();

                $body.on("afu-get-uploaded-files", function (event, options) { // kommt per ajaxfileuploader
                    if (doReloadTimer) window.clearTimeout(doReloadTimer);
                    doReloadTimer = window.setTimeout(doReload, 10); // kleiner debounce
                    if (options !== undefined && options.alfrescosearch !== undefined) {
                        // console.log("because of alfrescosearch and caching reloading in 20 seconds.");
                        if (doReloadTimerAS) window.clearTimeout(doReloadTimerAS);
                        window.setTimeout(function(){
                            jQuery("[data-afu-disclaimer]").addClass("alert-warning");
                        },20);
                        doReloadTimerAS = window.setTimeout(doReload, 20000); // Manche Tabelleninhalte werden gecached
                    }
                });
            });
    }).trigger("start");
});
