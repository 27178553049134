import './_jquery.js';
import './_bootstrap.js';
import './_table.js';

import '@ausschreibung/js/selectize.min.js';
import '@ausschreibung/js/lib/debounce.js';
import '@ausschreibung/js/lib/data-ajax-replace.js';
import '@ausschreibung/js/lib/data-limit-length.js';
import '@ausschreibung/js/lib/data-pagination.js';
import '@ausschreibung/js/lib/data-tooltip.js';
import '@ausschreibung/js/lib/addflash.js';
import '@ausschreibung/js/lib/body-event-escape.js';
import '@ausschreibung/js/lib/body-event-unselect.js';
import '@ausschreibung/js/lib/formSubmitNoEnter.js'; // nicht in form.js verschieben - es ist global
import '@ausschreibung/js/click-is-locked.js';
import '@ausschreibung/js/jszip.min.js';

import '@ausschreibung/js/flashbag.js';
import '@op/css/flashbag.scss';

// wir haben beides... modal und dialog - das sollten wir mal vereinheitlichen
import '@ausschreibung/js/lib/dialog.js';
import '@ausschreibung/css/dialog.css';
import '@ausschreibung/css/modal.css';
import '@op/css/pagination.scss';
import '@ausschreibung/js/lib/checkForNewGlobalMessages.js';