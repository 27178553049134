jQuery(function () {
    jQuery(document).bind("start load", function () {

        var $a = jQuery("a[href]").not("click-locks-link").addClass("click-locks-link");

        $a.bind("dblclick", function (event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        });

        $a.bind("click", function () {
            var $this = jQuery(this);
            window.setTimeout(function () {
                $this.addClass("click-is-locked");
            }, 1);
            window.setTimeout(function () {
                $this.removeClass("click-is-locked");
            }, 3000);
        });

        $(document).click(function (event) {
            if ($(event.target).is(".click-is-locked"))
                return false;
        });
    }).trigger("start");
});
